/**
 * Main CSS
 */

// General imports ------------------------------------------------------------
//
// These imports don't generate CSS, but make defined variables, functions,
// and mixins available for further usage
/* Locale Setting */
$locale: 'en';

@import 'base/config';
@import 'base/functions';
@import 'base/plugin';
@import 'base/reset';

/** Vendor **/
//@import 'vendor/jquery-ui';

/** Mixins */
@import 'mixin/all';


// Styles ---------------------------------------------------------------------
//@import 'module/layout';
@import 'module/header';
@import 'module/common';
@import 'module/mobile';
//@import 'module/01-topnav';
//@import 'module/03-footer';


// IE fix ---------------------------------------------------------------------
@import 'ie';

// Print ----------------------------------------------------------------------
//
// Inline to avoid the request
@import 'module/print';
