$org-color : #f7941d;
$duration: .20;
$easing: ease-in-out;

@font-face {
    font-family: 'gotham_book';
    src: url('../font/nvv_gotham-book-webfont.eot');
    src: url('../font/nvv_gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/nvv_gotham-book-webfont.woff2') format('woff2'),
         url('../font/nvv_gotham-book-webfont.woff') format('woff'),
         url('../font/nvv_gotham-book-webfont.svg#gotham_book') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'GothamNVV-Bold';
  src: url('../font/GothamNVV-Bold.eot?#iefix') format('embedded-opentype'),  
  url('../font/GothamNVV-Bold.otf')  format('opentype'),
  url('../font/GothamNVV-Bold.woff') format('woff'), 
  url('../font/GothamNVV-Bold.ttf')  format('truetype'), 
  url('../font/GothamNVV-Bold.svg#GothamNVV-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
    font-family: 'robotoregular';
    src: url('../font/roboto-regular-webfont.eot');
    src: url('../font/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-regular-webfont.woff2') format('woff2'),
         url('../font/roboto-regular-webfont.woff') format('woff'),
         url('../font/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../font/Roboto-Bold.eot?#iefix') format('embedded-opentype'),  
  url('../font/Roboto-Bold.woff') format('woff'), 
  url('../font/Roboto-Bold.ttf')  format('truetype'), 
  url('../font/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

h2,h3,h4 {
	font-family: 'GothamNVV-Bold';
	font-weight: normal;
}

html,body {
	font-family: 'robotoregular';
	color: #595959;
	font-size: 14px;
	width: 100%;
}

.container {
	max-width: 1170px;
	margin: 0 auto;
}
section {
	width: 100%;
	.container {
		padding-top: 40px;
	}
}
.btn-bg {
	font-size: 14px;
	display: inline-block;
	position: relative;
	background: $org-color;
	line-height: 40px;
	height: 40px;
	border-radius: 5px;
	text-align: center;
	padding: 0 30px;
	text-transform: uppercase;
	color: #fff;
	overflow: hidden;
	transition: all 200ms ease;
	border: 1px $org-color solid;

	span {
		display: inline-block;
		position: relative;
		z-index: 3;
	}
  
  &:before, &:after {
    content: '';
    padding-top: inherit;
    padding-bottom: inherit;
    white-space: nowrap;
    position: absolute;
    top: 0;
    overflow: hidden;
    background: #fff;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  &:before {
    left: 0;
    width: 0;
  }
  
  &:after {
    padding-left: inherit;
    padding-right: inherit;
    left: 100%;
    transition: $duration + s $easing;
  }
  
  &:hover {
  	color: $org-color;
  	border: 1px $org-color solid;

    &:before {
      width: 100%;
      transition: width $duration + s $easing;
    }
    
    &:after {
      left: 0;
      text-indent: 0;
      transition: 0s $duration + s $easing;
    }
  }
}

.btn-bd {
	font-size: 14px;
	display: inline-block;
	position: relative;
	line-height: 40px;
	height: 40px;
	border-radius: 5px;
	text-align: center;
	padding: 0 30px;
	text-transform: uppercase;
	color: $org-color;
	border: 1px $org-color solid;
	overflow: hidden;
	transition: all 200ms ease;

	span {
		display: inline-block;
		position: relative;
		z-index: 3;
	}
  
  &:before, &:after {
    content: '';
    padding-top: inherit;
    padding-bottom: inherit;
    white-space: nowrap;
    position: absolute;
    top: 0;
    overflow: hidden;
    background: $org-color;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  &:before {
    left: 0;
    width: 0;
  }
  
  &:after {
    padding-left: inherit;
    padding-right: inherit;
    left: 100%;
    transition: $duration + s $easing;
  }
  
  &:hover, 
  &.active {
  	color: #fff;

    &:before {
      width: 100%;
      transition: width $duration + s $easing;
    }
    
    &:after {
      left: 0;
      text-indent: 0;
      transition: 0s $duration + s $easing;
    }
  }
}


.banner {
	width: 100%;
	height: 100%;
	background-size: cover !important;

	.copy {
		text-align: center;
		 position: absolute;
	     left: 0;
	     top: 50%;
	     -ms-transform: translate(0, -50%);
	     -webkit-transform: translate(0, -50%);
	     transform: translate(0, -50%);
	     width: 100%;

		p {
			color: $org-color;
			font-family: 'gotham_book';
			font-size: 29px;
			text-transform: uppercase;
			background: rgba(255,255,255,.7);
			padding: 5px 10px;
			display: inline-block;
			margin-bottom: 9px;
			opacity: 0;
		      -ms-transform: translate(-100px, 0);
		    -webkit-transform: translate(-100px, 0);
		    transform: translate(-100px, 0);
		    transition-delay: 0.5s; 
		}
		h2 {
			color: $org-color;
			font-size: 57px;
			text-transform: uppercase;
			background: rgba(255,255,255,.7);
			padding: 13px 25px 10px 25px;
			display: inline-block;
			margin-bottom: 9px;
			opacity: 0;
            -ms-transform: translate(-100px, 0);
            -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0);
            transition-delay: 0.4s; 
		}
		h3 {
			color: $org-color;
			font-family: 'gotham_book';
			font-size: 29px;
			text-transform: uppercase;
			background: rgba(255,255,255,.7);
			padding: 5px 10px;
			display: inline-block;
			margin-bottom: 9px;
			opacity: 0;
		      -ms-transform: translate(-100px, 0);
		    -webkit-transform: translate(-100px, 0);
		    transform: translate(-100px, 0);
		    transition-delay: 0.2s; 
		}
		a {
			opacity: 0;
            -ms-transform: translate(-100px, 0);
            -webkit-transform: translate(-100px, 0);
            transform: translate(-100px, 0);
            transition-delay: 0; 
		}
	}
}

.animation {
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.animation2 {
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.animation-keep .animation-go .animation2 {
    opacity: 1 !important;
    -ms-transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}
.animation-keep .animation {
    opacity: 1 !important;
    -ms-transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}
.left {
	float: left;
	width: 50%;
	padding-right: 40px;
	opacity: 0;
	  -ms-transform: translate(-100px, 0);
	-webkit-transform: translate(-100px, 0);
	transform: translate(-100px, 0);
	transition-delay: 0; 
}
.right {
	float: right;
	width: 50%;
	padding-left: 40px;
	opacity: 0;
      -ms-transform: translate(100px, 0);
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0);
    transition-delay: 0.2s; 
}

.tittle {
	font-size: 40px;
	text-transform: uppercase;
	color: $org-color;
	padding-bottom: 30px;
	position: relative;
	line-height: 46px;

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 2px;
		height: 45px;
		background: $org-color;
		top: -65px;
		left: 0;
	}
}

.about-wrap {
	background: url(../images/demo/bg-intro.jpg) center center no-repeat;
	background-size: cover !important;

	.left {
		p {
			padding-bottom: 40px;
		}
	}
	.right {
		.img {
			text-align: center;
			img {
				max-width: 100%;
			}
		}
	}
}
.ct-btn {
	a {
		margin-right: 5px;
	}
}

.product-wrap {
	background: url(../images/demo/bg-product.jpg) center center no-repeat;
	background-size: cover !important;
	.container {
		// max-width: 1190px;
		padding-top: 115px;
	}
	.tittle {
		// margin-left: 10px;
		padding-bottom: 30px;
	}
	.slick-prev,
	.slick-next {
		top: -85px;
		-webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 30px;
        height: 50px;
	}
	.slick-prev {
		left: auto;
		right: 40px;
	}
}

.crs-pd {
	margin-left: -10px;
	margin-right: -10px;
}

.pd-item {
	padding: 0 10px;
	opacity: 0;
    -ms-transform: translate(0, 100px);
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px);

    &:nth-child(5) {
    	transition-delay: 0.0s; 
    }
    &:nth-child(6) {
    	transition-delay: 0.1s; 
    }
    &:nth-child(7) {
    	transition-delay: 0.2s; 
    }
    &:nth-child(8) {
    	transition-delay: 0.3s; 
    }

	&>div {
		background: #fff;
		border-radius: 4px;
		border: 1px #e0e0e0 solid;
		padding: 10px;
		position: relative;
		transition: all 200ms ease;

		.img {
			background-size: cover !important;
			transition: all 200ms ease;

			img {
				opacity: 0;
			}
		}
		.copy {
			padding: 20px;
			text-align: center;

			h3 {
				font-family: 'Roboto-Bold';
				text-transform: uppercase;
				padding-bottom: 10px;
				font-size: 16px;
				transition: all 200ms ease;
			}
			p {

			}
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
		&:hover {
			box-shadow: 0 0 10px #8f8f8f;

			.img {
				opacity: 0.88;
			}
			.copy {
				h3 {
					color: $org-color;
				}
			}
		}
	}
}

.project-wrap {
	background: url(../images/demo/bg-project.jpg) center center no-repeat;
	background-size: cover !important;

	.left {
		padding-top: 94px;
		p {
			padding-bottom: 40px;
		}
		.btn-bd {
			padding: 0 27px;
		}
	}
	.right {
		.img {
			img {
				width: 100%;
			}
		}
	}
	.slick-prev {
		left: -40px;
	}
	.slick-next {
		right: -40px;
	}
	.slick-dots {
		bottom: -24px;
	}
}
.crs-pj {
	margin-left: -10px;
	margin-right: -10px;
	width: 100%;
	display: none;

	&.active {
		display: block;
	}
}
.pj-item {
	padding: 10px;
	width: 50%;
	float: left;

	&>div {
		background: #fff;
		border-radius: 4px;
		border: 1px #e0e0e0 solid;
		padding: 10px;
		position: relative;
		transition: all 200ms ease;

		.img {
			background-size: cover !important;
			transition: all 200ms ease;

			img {
				opacity: 0;
			}
		}
		.copy {
			padding: 10px;
			padding-bottom: 0;
			text-align: center;

			h3 {
				font-family: 'Roboto-Bold';
				text-transform: uppercase;
				font-size: 16px;
				transition: all 200ms ease;
			}
			p {
				font-size: 13px;
				color: #797979;
			}
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
		&:hover {
			box-shadow: 0 0 10px #8f8f8f;

			.img {
				opacity: 0.88;
			}
			.copy {
				h3 {
					color: $org-color;
				}
			}
		}
	}
}

.contact-wrap {
	background: url(../images/demo/bg-contact.jpg) center center no-repeat;
	background-size: cover !important;

	.container {
		padding-top: 45px;
	}
	.left {
		padding-top: 20px;
		p {
			padding-bottom: 6px;
			a {
				color: #595959;
				text-decoration: none;

				&:hover {
					color: #f2a034;
				}
			}
		}


	}
	.right {
		padding-top: 76px;
		text-align: center;
		img {
			max-width: 100%;
		}
	}
}


.form-ct {
	padding-top: 10px;
	ul {
		display: block;
		width: 100%;
		float: left;

		li {
			display: block;
			width: 100%;
			margin-bottom: 10px;
			float: left;
		}
	}

	textarea {
        display: block;
        width: 100%;
        height: 88px;
        background: none;
        border: none;
        border-bottom: 1px #3d4453 solid;
        font-size: 12px;
        font-family: 'robotoregular';
        color: #868686;
        text-transform: uppercase;
        padding: 0 15px;
        margin-bottom: 10px;
        resize: none;

        &:focus {
          border-bottom: 1px #f9d409 solid;
          color: #f9d409;
        }
      }

      input[type="text"],
      input[type="email"] {
        display: block;
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px #3d4453 solid;
        font-size: 12px;
        font-family: 'robotoregular';
        color: #868686;
        height: 39px;
        text-transform: uppercase;
        padding: 0 15px;
        margin-bottom: 10px;

        &:focus {
          border-bottom: 1px $org-color solid;
          color: $org-color;
        }
      }
      a {
      	display: block;
      	float: right;
      }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: #868686;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: #868686;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: #868686;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: #868686;
	}
	input[type="submit"] {
		display: none;
	}
}

footer {
  text-align: center;
}

.ft-top {
  font-size: 13px;
  color: #5c5c5c;
  padding: 30px;
  
}
.ft-bot {
  font-size: 13px;
  color: #5c5c5c;
  padding: 30px;
  background: $org-color;
  text-transform: uppercase;
  color: #fff;
  
}

.hide-content {
	display: none;
}
.ft-ct {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	text-align: center;
}
