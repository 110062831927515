#hamburger {
  width: 50px;
  height: 40px;
  margin: 14px auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 10px;
  background-color: transparent;
  display: none;
}

// #hamburger:hover span:nth-child(1) {
//   top: -4px;
//   -webkit-transition: .20s ease-in-out;
//   -moz-transition: .20s ease-in-out;
//   -o-transition: .20s ease-in-out;
//   transition: .20s ease-in-out;
// }

// #hamburger:hover span:nth-child(3) {
//   top: 34px;
//   -webkit-transition: .16s ease-in-out;
//   -moz-transition: .16s ease-in-out;
//   -o-transition: .16s ease-in-out;
//   transition: .16s ease-in-out;
// }

#hamburger span {
  z-index: 999999999999 !important;
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .20s ease-in-out;
  -moz-transition: .20s ease-in-out;
  -o-transition: .20s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2) {
  top: 14px;
}

#hamburger span:nth-child(3) {
  top: 28px;
}

#hamburger.open span:nth-child(1) {
  top: 14px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
  -webkit-transition: .16s ease-in-out;
  -moz-transition: .16s ease-in-out;
  -o-transition: .16s ease-in-out;
  transition: .16s ease-in-out;
}

#hamburger.open span:nth-child(3) {
  top: 14px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media screen and (max-height: 708px) and (min-width: 1025px) { 
	.ft-bot {
		    padding: 13px 30px;
	}

}

@media screen and (max-height: 680px) and (min-width: 1025px) { 
	.product-wrap .tittle {
		padding-bottom: 0;
	}
	.tittle {
		padding-bottom: 0;
	}

	.left {
		width: 60%;
	}
	.right {
		width: 40%;
	}
	.form-ct ul li {
		margin-bottom: 0;
	}

}

@media screen and (max-width: 1180px) {
	.container {
		padding-left: 10px; 
		padding-right: 10px;
	}
	.tittle,
	.product-wrap .tittle {
		padding-bottom: 10px;
	}
	.about-wrap .left p {
    	padding-bottom: 10px;
	}
	.ct-btn {
		padding-top: 10px;
		.btn-bd {
			padding: 0 9px;
		}
	} 
	.pd-item {
		padding: 0 5px;
	}
	.crs-pd {
    	margin-left: -5px;
    	margin-right: -5px;
	}
	.project-wrap .left .btn-bd {
    	padding: 0 9px;
	}
	.form-ct ul li {
		margin-bottom: 0;
	}
	.project-wrap .slick-next,
	.project-wrap .slick-prev {
		background-color: rgba(255,255,255,.5); 
		border: 1px #e0e0e0 solid;
		width: 35px;
    	height: 61px;
	}
	.project-wrap .slick-next {
    	right: 0;
	}
	.project-wrap .slick-prev {
    	left: 0;
	}

}

@media screen and (max-width: 1024px) {
	header,
	.scroll-menu {
		height: 70px;
	    background: #f7941d;
	    box-shadow: 0 0 5px #8f8f8f;
	    padding-top: 6px;

	    .logo img {
		    height: 58px;
		}
		nav {
		    padding-top: 50px;
		}
		nav ul li a:after {
	    	display:none;
		}
		nav ul li a:hover, nav ul li a.active {
	    	color: #000;
	    	text-decoration: none;
		}
	}
 #hamburger {
 	display: block;
 }

	nav {
	  width: 100%;
	  height: 100%;
	  position: fixed;
	  top: 0;
	  right: -100%;
	  background-color: $org-color;

	  li {
	  	width: 100%;
	  	text-align: left;
	  	a {
	  		padding: 10px 0;
	  	}
	  }
	}
	nav ul li a:hover, nav ul li a.active {
    	color: #000;
    	text-decoration: none;
	}

	.animation,
	.animation2 {
	    opacity: 1 !important;
	    -ms-transform: translate(0, 0) !important;
	    -webkit-transform: translate(0, 0) !important;
	    transform: translate(0, 0) !important;
	}
}

@media screen and (max-width: 1023px) { 
	.fp-tableCell,
	.fp-section.fp-table, .fp-slide.fp-table {
		height: auto !important;
	}

	#section0 {
		.fp-tableCell,
		.fp-section.fp-table, .fp-slide.fp-table {
			height: 1000px !important;
		}
	}

	section .container {
    	padding-top: 80px;
    	padding-bottom: 30px;
	}
	.left,
	.right {
		width: 100%;
		padding: 0;
	}
	.left {
		margin-bottom: 20px;
	}
	.project-wrap .left {
    	padding-top: 0;
	}
	.contact-wrap .container {
    	padding-top: 80px;
	}
	.contact-wrap .right {
		padding-top: 0;
	}
}

@media screen and (max-width: 767px) { 
	header, .scroll-menu {
		height: 60px;
	}
	header .logo img, .scroll-menu .logo img {
    	height: 49px;
	}
	#hamburger {
		margin: 12px auto;
	}
	#hamburger.open {
		margin: 9px auto;
	}
	#section0 .fp-tableCell, #section0 .fp-section.fp-table, #section0 .fp-slide.fp-table {
    	height: 500px !important;
	}
	.banner .copy p {
		font-size: 24px;
	}
	.banner .copy h2 {
		font-size: 30px;
	}
	.tittle {
		font-size: 30px;
		line-height: 40px;
	}
	.tittle:after {
		height: 30px;
    	top: -33px;
	}
	section .container,
	.contact-wrap .container,
	.product-wrap .container {
		padding-top: 81px;
	}
	#hamburger span:nth-child(2) {
    	top: 10px;
	}
	#hamburger span:nth-child(3) {
    	top: 20px;
	}
	.pj-item {
		padding: 5px;
	}
	.crs-pj {
	    margin-left: -5px;
	    margin-right: -5px;
	    width: 100%;
	    display: none;
	}
	.project-wrap .left p {
		padding-bottom: 10px;
	}
	.ft-top,
	.ft-bot {
		padding: 10px; 
	}
	.contact-wrap .left {
		padding-top: 0;
	}
}

@media screen and (max-width: 460px) {
	.ct-btn .btn-bd,
	.form-ct .btn-bg {
		width: 100%;
		margin:0 
	}
}