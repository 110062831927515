header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  padding-top: 35px;
  transition: all 200ms ease;
  background: transparent;
}

.logo {
  display: inline-block;
  float: left;
  margin-right: 30px;
  transition: all 200ms ease;

  img {
    transition: all 200ms ease;
  }
}

nav {
  float: right;
  padding-top: 25px;
  transition: all 200ms ease;

  ul {
    li {
      display: block;
      float: left;
      padding: 0 15px;
      position: relative;
      a {
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        display: inline-block;
        padding: 13px 20px;
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 2px;
          background: #f7941d;
          position: absolute;
          left: 50%;
          bottom: 0;
          -ms-transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          transition: all 200ms ease;
        }

        &:hover,
        &.active {
          color: #f7941d;
          text-decoration: none;

          &:after {
            width: 46px;
          }
        }
      }
    }
  }
}

.scroll-menu {
  height: 70px;
  background: #f7941d;
  box-shadow: 0 0 5px #8f8f8f;
  padding-top: 6px;

  .logo {
    img {
      height: 58px;
    }
  }

  nav {
    padding-top: 7px;

    ul li a {
      &:hover,
        &.active {
          color: #595959;
          text-decoration: none;

          &:after {
            width: 46px;
            background: #595959;
          }
        }
    }
  }
}